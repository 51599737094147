<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <main class="md:ml-12 mr-4">
      <trac-back-button>Bank Account</trac-back-button>
      <div class="wrapper mb-8">
        <ul class="flex-col mt-12 ">
          <li><a href="#">Settings</a></li>
          <li class="font-bold mt-4 text-2xl">
            Add a Bank Account
          </li>
        </ul>
      </div>
      <div class=" px-8 py-8 bg-white h-full rounded-lg big-shadow">
        <div class="w-full md:w-8/12">
          <trac-stepper :steps="3" :current="currentTab"></trac-stepper>
        </div>
        <div class="my-12 grid md:flex flex-row gap-8" v-if="currentTab === 1">
          <trac-input
            v-model="accountCreationDetails.account_number"
            class="w-full md:w-6/12"
            placeholder="Account Number"
          ></trac-input>
          <trac-dropdown-exteneded
            :neededProperty="'name'"
            class="w-4/12"
            :options="banks"
            :selector="'Select Bank'"
            @optionSelected="selectBank"
          ></trac-dropdown-exteneded>
        </div>
        <div class="my-12 flex flex-row gap-8" v-else-if="currentTab === 2">
          <trac-input
            v-model="accountCreationDetails.bvn"
            class="w-full md:w-6/12"
            placeholder="BVN"
          ></trac-input>
        </div>
        <div class="my-2 flex flex-col gap-2" v-else-if="currentTab === 3">
          <h3 class="mt-4 text-primaryBlue">Verified!</h3>
          <p class="text-xs text-gray-600">Account details are verified.</p>
          <p class="text-xs text-gray-600">
            Account Number:
            <span class="text-gray-800 text-xs font-bold">{{
              accountCreationDetails.account_number
            }}</span>
          </p>
          <p class="text-xs text-gray-600">
            Bank:
            <span class="text-gray-800 text-xs font-bold">{{
              accountCreationDetails.settlement_bank
            }}</span>
          </p>
        </div>
      </div>
      <div class="flex flex-row justify-end mt-5">
        <trac-button
          @button-clicked="runCheck"
          :disabled="!validBankForm"
          class="uppercase"
          >{{ currentTab === 3 ? "Confirm Account" : "Continue" }}</trac-button
        >
      </div>
    </main>
  </div>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
} from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";

export default {
  name: "AddAccount",
  data() {
    return {
      isLoading: false,
      currentTab: 1,
      banks: null,
      selectedBank: null,
      updateAccountFlag: null,
      accountCreationDetails: {
        bvn: "",
        account_number: "",
        code: null,
        settlement_bank: null,
      },
    };
  },
  computed: {
    validBankForm() {
      if (this.currentTab === 1) {
        return (
          this.accountCreationDetails.account_number.toString().length > 0 &&
          this.accountCreationDetails.settlement_bank
        );
      } else if (this.currentTab === 2) {
        return this.accountCreationDetails.bvn.toString().length > 0;
      } else if (this.currentTab === 3) {
        return true;
      }
    },
  },
  async created() {
    this.updateAccountFlag = GET_LOCAL_DB_DATA("bank-account-details");

    if (this.updateAccountFlag) {
      this.accountCreationDetails = {
        ...this.updateAccountFlag,
        bvn: "",
        settlement_bank: null,
      };
    }

    this.isLoading = true;
    await this.fetchAllBanks();
    this.isLoading = false;
  },
  methods: {
    selectBank(bank) {
      this.accountCreationDetails.settlement_bank = bank.name;
      this.accountCreationDetails.code = bank.code;
    },
    runCheck() {
      if (this.currentTab === 1) {
        this.resolveBankAccount();
      } else if (this.currentTab === 2) {
        this.resolveBVN();
      } else if (this.currentTab === 3) {
        if (this.updateAccountFlag) {
          this.updateBankAccount();
        } else {
          this.addAccount();
        }
      }
    },
    async fetchAllBanks() {
      await this.$store.dispatch("FETCH_ALL_BANKS");
      const res = this.$store.getters["GET_ALL_BANKS"];

      if (res.status) {
        this.banks = res.data;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
    },
    async resolveBankAccount() {
      this.isLoading = true;
      const payload = {
        accountnumber: this.accountCreationDetails.account_number,
        bankcode: this.accountCreationDetails.code,
      };

      // Resolve account
      await this.$store.dispatch("RESOLVE_BANK_ACCOUNT", payload);

      const res = this.$store.getters["GET_ADD_BANK_RES"];

      this.$store.commit("POPULATE_ADDED_RES", null);

      if (res.status) {
        this.currentTab++;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async resolveBVN() {
      this.isLoading = true;

      // Resolve account
      await this.$store.dispatch("RESOLVE_BVN", this.accountCreationDetails);

      const res = this.$store.getters["GET_ADD_BANK_RES"];

      this.$store.commit("POPULATE_ADDED_RES", null);

      if (res.status) {
        this.currentTab++;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async addAccount() {
      this.isLoading = true;

      const payload = {
        settlement_bank: this.accountCreationDetails.settlement_bank,
        account_number: this.accountCreationDetails.account_number,
        business_name: GET_USER_BUSINESS_DATA().name,
        bvn: this.accountCreationDetails.bvn,
      };

      // Resolve account
      await this.$store.dispatch("ADD_NEW_ACCOUNT", payload);

      const res = this.$store.getters["GET_ADD_BANK_RES"];

      this.$store.commit("POPULATE_ADDED_RES", null);

      if (res.status) {
        await this.$store.dispatch("FETCH_ALL_BANK_ACCOUNTS");
        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
    async updateBankAccount() {
      this.isLoading = true;

      const payload = {
        settlement_bank: this.accountCreationDetails.settlement_bank,
        account_number: this.accountCreationDetails.account_number,
        business_name: GET_USER_BUSINESS_DATA().name,
        bvn: this.accountCreationDetails.bvn,
      };

      // Resolve account
      await this.$store.dispatch("EDIT_BANK_ACCOUNT", payload);

      const res = this.$store.getters["GET_ADD_BANK_RES"];

      this.$store.commit("POPULATE_ADDED_RES", null);

      if (res.status) {
        this.$router.back();
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: res.message });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
